<template>
  <div class="Company">
    <h2>企业库</h2>
    <el-button type="primary" @click="handleAdd" :disabled="onCheckBtnAuthority('CompanyAdd')">添加</el-button>
    <div class="searchWrap">
      <el-row>
        <el-col :span="5">
          <div class="sb">
            <span>统一社会信用代码</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="corpCode"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>企业名称</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="corpName"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="sb">
            <span>企业登记注册类型</span>
            <el-select
              v-model="corpType"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in typeList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="sb">
            <span>法定代表人姓名</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="legalMan"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" size="small" @click="onGetListData"
            >搜索</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="corpCode" label="统一社会信用代码">
      </el-table-column>
      <el-table-column prop="corpName" label="企业名称"> </el-table-column>
      <el-table-column prop="corpType" label="企业登记注册类型">
      </el-table-column>
      <el-table-column prop="legalMan" label="法定代表人姓名">
      </el-table-column>
      <el-table-column prop="registerDate" label="注册日期"> </el-table-column>
      <el-table-column align="right" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleDetail(scope.row)"  :disabled="onCheckBtnAuthority('CompanyDetail')"
            >详情</el-button
          >
          <el-button size="mini" @click="handleEdit(scope.row)"  :disabled="onCheckBtnAuthority('CompanyEdit')">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="企业信息"
      :visible.sync="showFormData"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="ruleForm"
        label-width="140px"
      >
        <!-- <el-form-item label="企业ID" prop="corpId">
          <el-input
            v-if="formDataMode != 'detail'"
            disabled
            v-model="formData.corpId"
          ></el-input>
          <span v-if="formDataMode == 'detail'">{{ formData.corpId }}</span>
        </el-form-item> -->
        <el-form-item label="企业名称" prop="corpName">
          <el-input
            v-if="formDataMode != 'detail'"
            v-model="formData.corpName"
          ></el-input>
          <span v-if="formDataMode == 'detail'">{{ formData.corpName }}</span>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="corpCode">
          <el-input
            v-if="formDataMode != 'detail'"
            v-model="formData.corpCode"
          ></el-input>
          <span v-if="formDataMode == 'detail'">{{ formData.corpCode }}</span>
        </el-form-item>
        <el-form-item label="企业登记注册类型" prop="corpType">
          <el-select
            v-if="formDataMode != 'detail'"
            v-model="formData.corpType"
            clearable
            class="searchInput"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in typeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <span v-if="formDataMode == 'detail'">{{ formData.corpType }}</span>
        </el-form-item>
        <el-form-item label="工商营业执照注册号" prop="licenseNumber">
          <el-input
            v-if="formDataMode != 'detail'"
            v-model="formData.licenseNumber"
          ></el-input>
          <span v-if="formDataMode == 'detail'">{{
            formData.licenseNumber
          }}</span>
        </el-form-item>
        <el-form-item label="注册地区" prop="registerArea">
          <el-input
            v-if="formData.registerArea && formDataMode != 'detail'"
            disabled
            v-model="formData.registerArea"
          ></el-input>
          <el-cascader
            v-if="!formData.registerArea && formDataMode != 'detail'"
            ref="adress"
            :props="props"
            @change="handleChange"
            v-model="registerArea"
            style="width: 100%"
          ></el-cascader>
          <span v-if="formDataMode == 'detail'">{{
            formData.registerArea
          }}</span>
        </el-form-item>
        <el-form-item label="企业营业地址" prop="address">
          <el-input
            v-if="formDataMode != 'detail'"
            v-model="formData.address"
          ></el-input>
          <span v-if="formDataMode == 'detail'">{{ formData.address }}</span>
        </el-form-item>
        <el-form-item label="法定代表人姓名" prop="legalMan">
          <el-input
            v-if="formDataMode != 'detail'"
            v-model="formData.legalMan"
          ></el-input>
          <span v-if="formDataMode == 'detail'">{{ formData.legalMan }}</span>
        </el-form-item>
        <el-form-item label="注册日期" prop="registerDate">
          <el-date-picker
            v-if="formDataMode != 'detail'"
            style="width: 100%"
            v-model="formData.registerDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <span v-if="formDataMode == 'detail'">{{
            formData.registerDate
          }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="handleConfirm"
          v-if="formDataMode != 'detail'"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    
<style lang="less" scoped>
.Company {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "Company",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      corpCode: "",
      corpName: "",
      corpType: "",
      legalMan: "",
      typeList: [],
      formData: {
        corpId: "", //企业ID
        corpCode: "", //统一社会信用代码(如果无统一社会信用代码，则填写组织机构代码)
        corpName: "", //企业名称
        corpType: "", //企业登记注册类型
        licenseNumber: "", // 工商营业执照注册号
        registerArea: "", // 注册地区
        address: "", //企业营业地址
        legalMan: "", //法定代表人姓名
        registerDate: "", //注册日期(精确到天,格式：yyyy-MM-dd)
      },
      formRules: {
        corpCode: [{ required: true, message: "必填项", trigger: "blur" }],
        corpName: [{ required: true, message: "必填项", trigger: "blur" }],
        corpType: [{ required: true, message: "必填项", trigger: "blur" }],
        registerArea: [{ required: true, message: "必填项", trigger: "blur" }],
        registerDate: [{ required: true, message: "必填项", trigger: "blur" }],
        legalMan: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      showFormData: false,
      formDataMode: null,
      registerArea: null,
      props: {
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
    };
  },
  computed: {},
  watch: {
    corpType: function () {
      this.pageNum = 1
      this.onGetListData();
    },
  },
  mounted() {
    this.onGetListData();
    this.onGetTypeList();
    this.onGetPageBtn()
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1685907206737301506",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取主列表数据
    onGetListData: function () {
      var that = this;
      var data = {
        status: 0,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        corpCode: this.corpCode,
        corpName: this.corpName,
        corpType: this.corpType,
        legalMan: this.legalMan,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/enterprise/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 获取企业类型数据
    onGetTypeList: function () {
      var that = this;
      var data = {
        type: "enterpriseType",
        className: "General",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/dictionary/common-interface",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.typeList = res.data;
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 添加企业
    handleAdd: function () {
      this.formData.corpId = "";
      this.formData.corpCode = "";
      this.formData.corpName = "";
      this.formData.corpType = "";
      this.formData.licenseNumber = "";
      this.formData.registerArea = "";
      this.formData.address = "";
      this.formData.legalMan = "";
      this.formData.registerDate = "";

      this.showFormData = true;
      this.registerArea = null;
      this.formDataMode = "save";
    },
    // 查看企业详情
    handleDetail: function (row) {
      var that = this;
      var data = {
        corpId: row.corpId,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/enterprise/detail",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.formData.corpId = res.data.corpId;
            that.formData.corpCode = res.data.corpCode;
            that.formData.corpName = res.data.corpName;
            that.formData.corpType = res.data.corpType;
            that.formData.licenseNumber = res.data.licenseNumber;
            that.formData.registerArea = res.data.registerArea;
            that.formData.address = res.data.address;
            that.formData.legalMan = res.data.legalMan;
            that.formData.registerDate = res.data.registerDate;

            that.showFormData = true;
            that.formDataMode = "detail";
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑企业信息
    handleEdit: function (row) {
      var that = this;
      var data = {
        corpId: row.corpId,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/enterprise/detail",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.formData.corpId = res.data.corpId;
            that.formData.corpCode = res.data.corpCode;
            that.formData.corpName = res.data.corpName;
            that.formData.corpType = res.data.corpType;
            that.formData.licenseNumber = res.data.licenseNumber;
            that.formData.registerArea = res.data.registerArea;
            that.formData.address = res.data.address;
            that.formData.legalMan = res.data.legalMan;
            that.formData.registerDate = res.data.registerDate;

            that.showFormData = true;
            that.formDataMode = "edit";
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭企业信息
    handleClose: function () {
      this.formData.corpId = "";
      this.formData.corpCode = "";
      this.formData.corpName = "";
      this.formData.corpType = "";
      this.formData.licenseNumber = "";
      this.formData.registerArea = "";
      this.formData.address = "";
      this.formData.legalMan = "";
      this.formData.registerDate = "";

      this.showFormData = false;
      this.registerArea = null;
      this.formDataMode = "";
      this.$refs['ruleForm'].resetFields();
    },
    // 选择省市区
    handleChange: function (val) {
      console.log(val);
      var node = this.$refs["adress"].getCheckedNodes(false);
      console.log("node", node);
      var address =
        node[0].label + node[0].parent.label + node[0].parent.parent.label;
      console.log("address", address);
      this.formData.registerArea = address;
    },
    // 省市区懒加载
    lazyLoad: function (node, resolve) {
      var code;
      if (node.hasOwnProperty("value")) {
        code = node.value;
      } else {
        code = "";
      }
      var that = this;
      var data = {
        code: code,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/region/select",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            const nodes = res.data.map((item) => ({
              value: item.code,
              label: item.name,
              leaf: node.level >= 2,
            }));
            resolve(nodes);
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 确认提交
    handleConfirm: function () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$confirm("此操作将提交数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.onSubmit();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 提交数据
    onSubmit: function () {
      var that = this;
      var data = this.formData;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-tower/enterprise/" + this.formDataMode,
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
            that.handleClose();
            that.onGetListData();
          } else {
            that.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    